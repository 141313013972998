import "./ThreeSixtyView.scss";
import React, { useEffect, useRef, useMemo, useState } from "react";
import * as PANOLENS from "panolens";
import { FaAngleLeft, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function ThreeSixtyView({
  type = "image",
  source = "/360.jpg",
  points = [],
  sensor = false,
}) {
  const ref = useRef();
  let viewer = useRef();
  let [hoverInfo, setHoverInfo] = useState({});
  let nav = useNavigate();

  const panorama = useMemo(() => {
    let p;
    if (type === "image") {
      p = new PANOLENS.ImagePanorama(source);
    }
    if (type === "video") {
      p = new PANOLENS.VideoPanorama(source, {
        autoplay: true,
        loop: true,
      });
    }

    return p;
  }, [type, source]);
  useEffect(() => {
    console.log(panorama, panorama.remove);

    for (let i = 0; i < panorama.children.length; i++) {
      panorama.remove(panorama.children[i]);
      panorama.children[i].dispose();
    }
    points.forEach((point) => {
      const infospot = new PANOLENS.Infospot();
      infospot.position.set(...point.position);
      if (point.hoverText) {
        infospot.addHoverText(point.hoverText);
        infospot.addEventListener("click", point.onClick);
      } else {
        infospot.addHoverText("Klick mich");
        infospot.addEventListener("click", () => {
          if (hoverInfo.title === point.hoverInfo.title) {
            setHoverInfo({});
          } else {
            setHoverInfo(point.hoverInfo);
          }
        });
      }
      console.log(panorama);
      panorama.add(infospot);
    });
    panorama.toggleInfospotVisibility(true);
  }, [points, panorama, hoverInfo]);
  useEffect(() => {
    viewer.current = new PANOLENS.Viewer({ enableReticle: false });

    viewer.current.reticle.setColor(0x77ffff);

    // Add to left panel

    viewer.current.add(panorama);

    return () => {
      viewer.current.remove(panorama);
      viewer.current.destroy();
      let container = viewer.current.getContainer();
      container.parentElement.removeChild(container);
    };
  }, [panorama]);

  useEffect(() => {
    viewer.current.enableControl(sensor ? 1 : 0);
    if (sensor) {
      viewer.current.enableReticleControl(true);
    } else {
      viewer.current.disableReticleControl(true);
    }
  }, [sensor]);

  return (
    <>
      {hoverInfo.title && (
        <div className="info">
          <div className="infoImg">
            <img alt="infoImg" src={hoverInfo.image}></img>
          </div>
          <div className="rest">
            <b>{hoverInfo.title}</b>
            <br></br>
            {hoverInfo.text}
          </div>
          <div className="closeButton" onClick={() => setHoverInfo({})}>
            <FaTimes></FaTimes>
          </div>
        </div>
      )}

      <div style={{ height: "100%", position: "relative" }}>
        <div style={{ position: "absolute", top: 0, left: 0, zIndex: 120 }}>
          <div ref={ref}></div>
          
          <div
          className="backtoapp"
            onClick={() => {
              nav("/");
            }}
          >
          <FaAngleLeft></FaAngleLeft>
          </div>
        </div>
      </div>
    </>
  );
}
