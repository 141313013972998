import React from "react";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useUser } from "../../zustand/useUser";
import Button from "../Button/Button";
import Spacer from "../Spacer/Spacer";
import "./Nav.scss";

export default function Nav({ open, onClose }) {
  let { difficulty, setDifficulty } = useUser();

  if (!open) {
    return null;
  }
  return (
    <div className="Nav">
      <div className="navHeader">
        <div className="logo">
          <img alt="logo" src="./logo.png"></img>
        </div>
      </div>
      <div className="navInner">
        <div className="navWrap">
          <NavItem path="/">Startseite</NavItem>
          <NavItem path="/forscher/frage">Forscher</NavItem>
          <NavItem path="/co2">Nachhaltigkeits-Trainer</NavItem>
          <NavItem path="/kambodschaControls">Kambodscha Virtual Reality</NavItem>
          <NavItem path="/turmControls">Turm Virtual Reality</NavItem>
          <NavItem path="/sammlung">Meine Karten</NavItem>
          {difficulty && (
            <div
              className="navItem"
              onClick={() => {
                setDifficulty(difficulty === "easy" ? "hard" : "easy");
              }}
            >
              Schwierigkeit: {difficulty === "easy" ? "Neuling" : "Experte"}
            </div>
          )}
          <NavItem path="/">Forscherapp verlassen</NavItem>
          <Spacer h={24} />
          <div className="cardBoost">
            <div className="title">Meine Karten</div>
            <Spacer h={6} />
            <div className="text">
              Du bist Erfahrener Expeditör und hast richtig Lust zu Forschen?
              Dann bist Du hier genau richtig!
              <Spacer h={12} />
              <Button href={"/sammlung"}>Sammlung</Button>
            </div>
          </div>

          <div className="navFooter">
            <div className="impressum">Impressum {"&"} Datenschutz</div>
            <Spacer h={8} />
            <div className="credits">© 2021 Allwetterzoo Münster</div>
          </div>
        </div>
      </div>
      <div className="closeNav" onClick={() => onClose()}>
        <FaTimes></FaTimes>
      </div>
    </div>
  );
}

function NavItem({ children, path }) {
  return (
    <Link to={path} className="navItem">
      {children}
    </Link>
  );
}
