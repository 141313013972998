import React from "react";

import Layout from "../../atoms/Layout/Layout";
import "./Halle.scss";

export default function Halle() {
  return (
    <Layout className="Halle">
      <div className="introText">
        <div className="title">
          Willkommen in
          <br />
          deiner Meranti-Halle
        </div>
        Willkommen in der Meranti-Halle: Einer Tropenhalle wie ein Baum, fest
        verwurzelt in der Region und hochgewachsen in der Landschaft, Lebensraum
        für viele Tiere und Pflanzen, die in den unterschiedlichsten Stockwerken
        leben. Genauso wie der Namensgeber dieser Halle: der Merantibaum.
      </div>
      <div className="apps">
        <div className="app">
          <div className="img">
            <img alt="a giraffe" src="./halle5.jpeg"></img>
          </div>
          <div className="title">DIE NEUE MERANTI-HALLE AUF EINEN BLICK</div>
          <div className="text">
            Die Meranti-Halle des Allwetterzoos Münster eröffnete am 23. Juni
            2023 und ist die neue Heimat für mehr als 15 exotische Tierarten.
            Die neue Halle, benannt nach dem in den asiatischen Tropen
            beheimateten Merantibaum, entstand nach 30-monatiger Bauzeit direkt
            am Eingang des Allwetterzoos. Künftig können hier große und kleine
            Besucherinnen und Besucher Tiere erleben.
            <br></br>
            <br></br>
            <b>Fakten</b>
            <ul>
              <li>2.500 m² Hallenfläche</li>
              <li>11.000 m² Außenfläche</li>
              <li>16 m hoch</li>
              <li>Bauzeit: 30 Monate</li>
              <li>mehr als 15 Tierarten z. T. mit IUCN-Bedrohungsstatus</li>
              <li>Höhenweg in 7 m Höhe</li>
              <li>Interaktives Erlebnis über die App der Meranti-Halle</li>
              <li>Mietbarer Event-Bereich</li>
              <li>Weitestgehend klimaneutrale Energieversorgung</li>
            </ul>
            <br></br>
            <br></br>
            <b>Tiere der Meranti-Halle</b>
            <ul>
              <li>Riesenotter</li>
              <li>Flachlandtapir</li>
              <li>Zweifingerfaultier </li>
              <li>Großer Ameisenbär</li>
              <li>Schwarzer Brüllaffe</li>
              <li>Ringelschwanzmungo</li>
              <li>Roter Varis</li>
              <li>Königspython</li>
              <li>Gebänderter Fidschileguan</li>
              <li>Chinesische Krokodilschwanzhöckerechse</li>
              <li>Köhlerschildkröten</li>
              <li>Kleiner Winkerfrosch</li>
              <li>diverse Vögel, Reptilienarten und Insekten</li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
}
