import React from "react";
import ActionLink from "../../atoms/ActionLink/ActionLink";
import Button from "../../atoms/Button/Button";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import "./ForscherStart.scss";

export default function ForscherStart() {
  return (
    <Layout className="ForscherStart">
      <GameCard></GameCard>
    </Layout>
  );
}

function GameCard() {
  return (
    <div className="gameCard">
      <div className="img">
        <img alt="people at the zoo" src="/zoo.jpg" className="src" />
      </div>
      <div className="title">Forschen</div>
      <div className="text">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet
        doloremque perferendis impedit cumque veniam fugiat! Pariatur, earum
        eveniet ex natus id, enim suscipit non officiis, sapiente quae ullam hic
        odio!
      </div>
      <div className="flex1"></div>
      <Button big green rotate href="/forscher/frage">
        Los geht's!
      </Button>
      <Spacer h={12} />
      <ActionLink>Zurück</ActionLink>
      <div className="flex1"></div>
    </div>
  );
}
