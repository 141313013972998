import create from "zustand";
import { persist } from "zustand/middleware";

export const useCo2State = create(
  persist(
    (set, get) => ({
      states: {  },
      currentRoom: "flur",
      setCurrentRoom: (currentRoom) => set({ currentRoom }),
      setStates: (states) => {
        set({ states });
      },
    }),
    {
      name: "co2-storage2", // unique name
    }
  )
);
