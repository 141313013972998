import React from "react";
import "./App.scss";
import "./fonts.scss";

import { Route, Routes } from "react-router";
import Home from "./screens/Home/Home";
import Forscher from "./screens/Forscher/Forscher";
import Sammlung from "./screens/Sammlung/Sammlung";
import Co2 from "./screens/Co2/Co2";
import Turm from "./screens/Turm/Turm";
import Splash from "./screens/Splash/Splash";
import Result from "./screens/Result/Result";
import ForscherStart from "./screens/ForscherStart/ForscherStart";
import ForscherDifficulty from "./screens/ForscherDifficulty/ForscherDifficulty";
import Forscher2 from "./screens/Forscher2/Forscher2";
import Co2character from "./screens/Co2character/Co2character";
import Kambodscha from "./screens/Kambodscha/Kambodscha";
import Kambodscha2 from "./screens/Kambodscha2/Kambodscha2";
import Co2Result from "./screens/Co2Result/Co2Result";
import TurmEntry from "./screens/TurmEntry/TurmEntry";
import Baum from "./screens/Baum/Baum";
import Spiele from "./screens/Spiele/Spiele";
import Halle from "./screens/Halle/Halle";
import TurmControls from "./screens/TurmControls/TurmControls";
import KambodschaEntry from "./screens/KambodschaEntry/KambodschaEntry";

function App() {
  return (
    <Splash>
      <Routes>
        <Route path={"/"} element={<Home />}></Route>
        <Route path={"/baum"} element={<Baum />}></Route>
        <Route path={"/halle"} element={<Halle />}></Route>
        <Route path={"/spiele"} element={<Spiele />}></Route>
        <Route path={"/forscher"} element={<ForscherStart />}></Route>
        <Route path={"/forscher/frage"} element={<Forscher />}></Route>
        <Route path={"/forscher/frage2"} element={<Forscher2 />}></Route>
        <Route
          path={"/forscher/level"}
          element={<ForscherDifficulty />}
        ></Route>
        <Route path={"/forscher/result"} element={<Result />}></Route>
        <Route path={"/sammlung"} element={<Sammlung />}></Route>
        <Route path={"/co2game"} element={<Co2 />}></Route>
        <Route path={"/co2"} element={<Co2character />}></Route>
        <Route path={"/co2result"} element={<Co2Result />}></Route>
        <Route path={"/turm"} element={<TurmEntry />}></Route>
        <Route path={"/turmControls"} element={<TurmControls />}></Route>
        <Route path={"/turm/:level"} element={<Turm />}></Route>
        <Route
          path={"/kambodschaControls"}
          element={<KambodschaEntry></KambodschaEntry>}
        ></Route>
        <Route path={"/kambodscha"} element={<Kambodscha></Kambodscha>}></Route>

        <Route path={"/vrselect"} element={<Kambodscha2></Kambodscha2>}></Route>
      </Routes>
    </Splash>
  );
}

export default App;
