import React, { useEffect, useState } from "react";
import "./Splash.scss";
import images from "../../images";
import Loading from "../../atoms/Loading/Loading";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";

export default function Splash({ children }) {
  let [status, setStatus] = useState("loading");
  useEffect(() => {
    let promises = Object.values(images).map((imgUrl) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imgUrl;
        img.onload = resolve;
        img.onerror = resolve;
      });
    });
    Promise.all(promises).then(() => {
      console.log("loaded all images");
      setStatus("loaded");
    });
  }, []);
  if (status === "loading") {
    return <LoadingScreen></LoadingScreen>;
  }
  return children;
}

export function LoadingScreen() {
  return (
    <Layout navHidden>
      <div className="loadingScreen">
        <div className="loadingText">Die Forscherapp startet grade!</div>
        <Spacer h={36} />
        <Loading big></Loading>
      </div>
    </Layout>
  );
}
