import { useSpring, animated } from "@react-spring/web";
import React, { useState, useEffect } from "react";
import create from "zustand";
import { useNavigate, useParams } from "react-router-dom";
import TrumpCard from "../../atoms/TrumpCard/TrumpCard";
import Pano from "../Threesixty/Threesixty";
import "./Turm.scss";
import { FaAngleLeft, FaTimes } from "react-icons/fa";
import { useDrag } from "@use-gesture/react";
import data from "../../data";
import { downImage, upImage } from "./images";
import blatt_gruen from "../Kambodscha/blatt_gruen";
import blatt_orange from "../Kambodscha/blatt_orange";

export let usePano = create((set) => ({
  sensorMode: false,
  reticle: false,
  setSensorMode: (sensorMode) => set({ sensorMode }),
  setReticle: (reticle) => set({ reticle }),
}));

function getYheight() {
  return (Math.random() - 0.5) * 2000;
}

function angleToVector(angle, distance, height = null) {
  return [
    Math.cos(angle) * distance,
    height !== null ? height : getYheight(),
    Math.sin(angle) * distance,
  ];
}

export default function Turm() {
  let { level } = useParams();
  let [overlay, setOverlay] = useState(null);

  let nav = useNavigate();
  let [{ height }, api] = useSpring(() => ({ height: "0" }));
  const [audio] = useState(new Audio("/cam.wav"));

  const mapped = data.lebewesen.map((l, i) => {
    return { ...l, index: i };
  });
  const icons = [
    mapped.filter((l) => {
      return l.etage === "1" || l.etage === "2";
    }),
    mapped.filter((l) => {
      return l.etage === "3";
    }),
    mapped.filter((l) => {
      return l.etage === "4";
    }),
    mapped.filter((l) => {
      return l.etage === "5" || l.etage === "6";
    }),
  ];

  const views = {
    1: {
      image: "/turm_images/1.jpg",
      points: [
        ...icons[0].map((l, i) => {
          return {
            icon: l.type === "Pflanze" ? blatt_orange : blatt_gruen,
            scale: 6,
            position: angleToVector(
              ((Math.PI * 2) / icons[0].length) * i,
              3000
            ),
            text: "",
            onClick: () => {
              setOverlay(l.index);
              audio.play();
              api.start({ height: "100%" });
            },
          };
        }),

        {
          icon: upImage,
          scale: 3,
          position: [0, 2000, 0],
          text: "",
          onClick: () => {
            audio.play();
            //api.start({ height: "100%" });
            nav("/turm/" + (Number(level) + 1));
          },
        },
      ],
    },
    2: {
      image: "/turm_images/2.jpg",
      points: [
        ...icons[1].map((l, i) => {
          return {
            icon: l.type === "Pflanze" ? blatt_orange : blatt_gruen,
            scale: 6,
            position: angleToVector(
              ((Math.PI * 2) / icons[1].length) * i,
              3000
            ),
            text: "",
            onClick: () => {
              setOverlay(l.index);
              audio.play();
              api.start({ height: "100%" });
            },
          };
        }),
        {
          icon: upImage,
          scale: 3,
          position: [0, 2000, 0],
          text: "",
          onClick: () => {
            audio.play();
            //api.start({ height: "100%" });
            nav("/turm/" + (Number(level) + 1));
          },
        },
        {
          icon: downImage,
          scale: 3,
          position: [0, -2000, 0],
          text: "",
          onClick: () => {
            audio.play();
            //api.start({ height: "100%" });
            nav("/turm/" + (Number(level) - 1));
          },
        },
      ],
    },
    3: {
      image: "/turm_images/3.jpg",
      points: [
        ...icons[2].map((l, i) => {
          return {
            icon: l.type === "Pflanze" ? blatt_orange : blatt_gruen,
            scale: 6,
            position: angleToVector(
              ((Math.PI * 2) / icons[2].length) * i,
              3000
            ),
            text: "",
            onClick: () => {
              setOverlay(l.index);
              audio.play();
              api.start({ height: "100%" });
            },
          };
        }),
        {
          icon: upImage,
          scale: 3,
          position: [0, 2000, 0],
          text: "",
          onClick: () => {
            audio.play();
            //api.start({ height: "100%" });
            nav("/turm/" + (Number(level) + 1));
          },
        },
        {
          icon: downImage,
          scale: 3,
          position: [0, -2000, 0],
          text: "",
          onClick: () => {
            audio.play();
            //api.start({ height: "100%" });
            nav("/turm/" + (Number(level) - 1));
          },
        },
      ],
    },
    4: {
      image: "/turm_images/4.jpg",
      points: [
        ...icons[3].map((l, i) => {
          return {
            icon: l.type === "Pflanze" ? blatt_orange : blatt_gruen,
            scale: 6,
            position: angleToVector(
              ((Math.PI * 2) / icons[3].length) * i,
              3000
            ),
            text: "",
            onClick: () => {
              setOverlay(l.index);
              audio.play();
              api.start({ height: "100%" });
            },
          };
        }),
        {
          icon: upImage,
          scale: 3,
          position: [0, 2000, 0],
          text: "",
          onClick: () => {
            audio.play();
            //api.start({ height: "100%" });
            nav("/turm/" + (Number(level) + 1));
          },
        },
        {
          icon: downImage,
          scale: 3,
          position: [0, -2000, 0],
          text: "",
          onClick: () => {
            audio.play();
            //api.start({ height: "100%" });
            nav("/turm/" + (Number(level) - 1));
          },
        },
      ],
    },
  };

  return (
    <>
      <animated.div
        style={{ height }}
        className="turmOverlay"
        onClick={() => {
          api.start({ height: "0%" });
        }}
      >
        <div
          className="inner"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <TrumpCard data={data.lebewesen[overlay]}></TrumpCard>
        </div>
        <div
          className="closeOverlay"
          onClick={() => api.start({ height: "0%" })}
        >
          Schliessen
        </div>
      </animated.div>

      <Pano
        image={views[level].image}
        points={views[level].points}
        level={level}
        key={1}
      >
        {(viewer) => {
          return (
            <>
              <div style={{ height: "100%", position: "relative" }}></div>
              <ControlsSettings viewer={viewer}></ControlsSettings>
              <div className="closeButton" onClick={() => nav("/")}>
                <FaTimes></FaTimes>
              </div>
            </>
          );
        }}
      </Pano>
      <TurmLevelIndicator level={level - 1}></TurmLevelIndicator>
    </>
  );
}

export function ControlsSettings({ viewer }) {
  let { sensorMode, setSensorMode, reticle, setReticle } = usePano();
  let [settingsOpen, setSettingsOpen] = useState(false);
  let nav = useNavigate();
  const { level } = useParams();

  return (
    <div className="panoControls">
      {!settingsOpen && (
        <div className="controlsButton" onClick={() => setSettingsOpen(true)}>
          Einstellungen
        </div>
      )}
      {settingsOpen && (
        <div className="settingsList">
          {!sensorMode && (
            <button
              onClick={() => {
                if (
                  DeviceMotionEvent &&
                  DeviceMotionEvent.requestPermission &&
                  typeof DeviceMotionEvent.requestPermission === "function"
                ) {
                  DeviceMotionEvent.requestPermission().then(() => {
                    setSensorMode(true);
                    viewer.current.enableControl(1);
                    setSettingsOpen(false);
                  });
                } else {
                  setSensorMode(true);
                  viewer.current.enableControl(1);
                  setSettingsOpen(false);
                }
              }}
            >
              Sensor aktivieren
            </button>
          )}
          {sensorMode && (
            <button
              onClick={() => {
                if (
                  DeviceMotionEvent &&
                  DeviceMotionEvent.requestPermission &&
                  typeof DeviceMotionEvent.requestPermission === "function"
                ) {
                  DeviceMotionEvent.requestPermission().then(() => {
                    setSensorMode(false);
                    viewer.current.enableControl(0);

                    setSettingsOpen(false);
                  });
                } else {
                  setSensorMode(false);
                  viewer.current.enableControl(0);

                  setSettingsOpen(false);
                }
              }}
            >
              Sensor aktivieren
            </button>
          )}
          {!reticle && (
            <button
              onClick={() => {
                setReticle(true);
                viewer.current.enableReticleControl();

                setSettingsOpen(false);
              }}
            >
              Blicksteuerung
            </button>
          )}
          {reticle && (
            <button
              onClick={() => {
                setReticle(false);
                viewer.current.disableReticleControl();

                setSettingsOpen(false);
              }}
            >
              Blicksteuerung deaktivieren
            </button>
          )}

          {level < 4 && (
            <button
              onClick={() => {
                nav("/turm/" + (1 + Number(level)));

                setSettingsOpen(false);
              }}
            >
              Stockwerk nach oben
            </button>
          )}
          {level > 1 && (
            <button
              onClick={() => {
                nav("/turm/" + (-1 + Number(level)));

                setSettingsOpen(false);
              }}
            >
              Stockwerk nach unten
            </button>
          )}

          <button
            onClick={() => {
              setSettingsOpen(false);
            }}
          >
            Einstellungen schliessen
          </button>
        </div>
      )}
    </div>
  );
}

function TurmLevelIndicator({ level }) {
  let nav = useNavigate();
  const bind = useDrag(({ movement, active, xy }) => {
    let offsetts = (level - 1) * 4;
    let m = movement[1] - offsetts;
    let perc = 100 - level * 33 + (m / 200) * 100;
    api.start({
      top: perc + "%",
    });
    //new level:
    let newLevel = Math.round((100 - perc) / 33);
    if (!active) {
      if (level !== newLevel && newLevel >= 0 && newLevel <= 3) {
        nav("/turm/" + (Number(newLevel) + 1));
      } else {
        api.start({
          top: 100 - level * 33 + "%",
        });
      }
    }
  });
  let [{ top }, api] = useSpring(() => ({
    top: 100 - level * 33 + "%",
  }));
  useEffect(() => {
    api.start({ top: 100 - level * 33 + "%" });
  }, [level, api]);
  return (
    <div className="turmLevelIndicator">
      <img src="/baum.png" alt="Merantibaum"></img>
      <animated.div {...bind()} className="currentLevel" style={{ top }}>
        <FaAngleLeft></FaAngleLeft>
      </animated.div>
    </div>
  );
}
