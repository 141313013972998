import React from "react";
import Layout from "../../atoms/Layout/Layout";
import "./Home.scss";
import Button from "../../atoms/Button/Button";

export default function Home() {
  return (
    <Layout className="Home">
      <div className="introText">
        <div className="title">
          DIE APP ZUR MERANTI-HALLE -
          <br />
          EINE APP, SO VIELFÄLTIG WIE DIE TROPEN SELBST.
        </div>
        Die App zur Meranti-Halle bietet dir die digitale Möglichkeit, die
        Tropenhalle des Allwetterzoo Münster auf besondere Art und Weise zu
        erleben. Du kannst die Tier- und Pflanzenwelt erforschen, etwas über die
        Arbeit in unserem Artenschutzzentrum in Kambodscha erfahren und
        spielerisch erleben, wie einfach es sein kann, zu Hause etwas für den
        Arten- und Klimaschutz zu tun.
        <br></br>
        <br></br>
        Werde spielerisch aktiv und lerne die Tropen besser kennen.
      </div>
      <div className="apps">
        <div className="app">
          <div className="img">
            <img
              alt="donkeys"
              src="./Menara from drone_Alexander Shenkin.JPG"
            ></img>
            <div className="credit">
              Foto: Drohnenansicht Gelber Merantibaum „Menara“/Borneo, Alexander
              Shenkin
            </div>
          </div>
          <div className="title">Der Merantibaum</div>
          <div className="text">
            Der immergrüne Gelbe Merantibaum (Shorea faguetiana) ist eine
            asiatische Pflanzenart aus der Gattung Shorea in der Familie der
            Flügelfruchtgewächse (Dipterocarpaceae). Er erreicht eine Höhe von
            durchschnittlich 50 bis 60 m, kann allerdings auch um die 100 m hoch
            werden. Der grau-braune Stamm dieses Merantibaumes kann einen
            Durchmesser von über 3 m erreichen und bildet zum Teil meterhohe
            Brettwurzeln aus. Seine duftenden, creme-farbenen Blüten stehen in
            bis zu 20 cm langen Rispen, die später daraus entstehen
            Flügelfrüchte sind orange-braun. Baumgiganten wie der Gelbe
            Merantibaum sind Lebensraum für viele Pflanzen und Tiere und somit
            wichtig für den Erhalt der Artenvielfalt (Biodiversität) im
            Regenwald.
          </div>
          <div className="flex1"></div>
          <Button green rotate href="/baum">
            Mehr Infos
          </Button>
        </div>
        <div className="app">
          <div className="img">
            <img alt="a giraffe" src="./halle3.jpeg"></img>
          </div>
          <div className="title">Die Meranti-Halle</div>
          <div className="text">
            Die Meranti-Halle ist eine Tropenhalle wie ein Baum - fest
            verwurzelt in der Region und hochgewachsen in der Landschaft sowie
            Lebensraum für viele Tiere und Pflanzen, die in den
            unterschiedlichsten Stockwerken leben. Genauso wie der Namensgeber
            dieser Halle: der Merantibaum.
            <br></br>
            <br></br>
            Lerne die Meranti-Halle des Allwetterzoo Münster kennen und entdecke
            ihre tropische Tier- und Pflanzenwelt.
          </div>
          <div className="flex1"></div>
          <Button green rotate href="/halle">
            Erkunde die Meranti-Halle
          </Button>
        </div>
      </div>
      <div className="apps">
        <div className="app">
          <div className="img">
            <img alt="happy people at the zoo" src="./Handy PSD.png"></img>
          </div>
          <div className="title">Spiele</div>
          <div className="text">
            Hier kannst du die Flora und Fauna unserer Meranti-Halle erforschen
            und entdecken. Viele Spaß dabei!
          </div>
          <div className="flex1"></div>
          <Button href={"/spiele"} green rotate>
            Entdecke unsere Spiele
          </Button>
        </div>
      </div>
    </Layout>
  );
}
