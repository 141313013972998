import React, { useState } from "react";
import Spacer from "../../atoms/Spacer/Spacer";
import { useUser } from "../../zustand/useUser";
import { useCo2State } from "../../zustand/useCo2State";
import "./Co2.scss";
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

export const maxPoints = 360;

export function calculateScore(rooms, states) {
  let points = 0;
  // for each state, find score
  //flatten the room items
  let allItems = [];
  Object.values(rooms).forEach((o) => {
    allItems = allItems.concat(o.items);
  });

  Object.entries(states).forEach(([key, value]) => {
    let relevantItem = allItems.find((i) => i.name === key);
    console.log(relevantItem, allItems, key);
    let itemState = relevantItem.states[value];
    points += itemState.points;
  });

  return points;
}

export const rooms = {
  schlafzimmer: {
    arrows: [{ img: "pl", room: "flur" }],
    items: [
      {
        popupText:
          "Nackte Tatsachen, die gibt es in der Textilbranche zu genüge. Aber weißt Du auch, was bei Dir im Kleiderschrank hängt?",
        name: "textil",
        pos: { left: "70.1%", top: "16%" },
        size: 31,
        states: [
          {
            id: 0,
            img: "/co2/schrank_03.png",
            label: "Immer Up To Date.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/schrank_02.png",
            label: "Ich trage immer alles auf.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/schrank_01.png",
            label: "Ich achte auf Label oder kaufe Second-Hand.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
      {
        popupText:
          "Frische Luft tut immer gut. Aber wie wird am besten gelüftet?",
        name: "leuften",
        pos: { left: "29.1%", top: "10%" },
        size: 13,
        states: [
          {
            id: 0,
            img: "/co2/fenster_03.png",
            label: "Bei mir ist das Fenster immer auf Kipp.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/fenster_02.png",
            label: "Ich lüfte immer nur Raum für Raum.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/fenster_01.png",
            label: "Kurzes Stoßlüften über mehrere Räume.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
      {
        popupText:
          "Bett, Kleiderschrank und Schreibtisch, aus was sind die Stücke gefertigt?",
        name: "moebel",
        pos: { left: "22.1%", top: "26%" },
        size: 18,
        states: [
          {
            id: 0,
            img: "/co2/bett_03.png",
            label: "Die Händler passen schon auf.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/bett_02.png",
            label: "Der Mindeststandard ist das FCS-Siegel.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/bett_01.png",
            label: "Alte Möbel neu aufbereitet.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
    ],
    charPos: { left: "45%", top: "43%" },
    name: "Schlafzimmer",
    description:
      "Dein Zimmer: Dein Rückzugsraum, um abzuschalten und Kraft zu tanken. Aber auch das Klima kann hier Kraft tanken und Energie gewinnen. Es ist ganz einfach. Schau Dich nur mal um.",
  },
  flur: {
    items: [
      {
        popupText:
          "Raus vor die Tür, das nächste Ziel vor Augen. Aber wie erreicht Ihr dies?",
        name: "raus",
        pos: { left: "25.1%", top: "11%" },
        size: 8,
        states: [
          {
            id: 0,
            img: "/co2/schluessel_03.png",
            label: "So schnell wie möglich. Das geht nur mit meinem Auto.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/schluessel_02.png",
            label:
              "Wenn möglich, dann nutze ich den öffentlichen Personennahverkehr.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/schluessel_01.png",
            label:
              "Fahrrad. Damit erreiche ich fast alles. Ansonsten Bus, Bahn oder Carsharing.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
      {
        popupText: "Wie viele Säcke Müll bringt Ihr vor die Tür?",
        name: "Muell",
        pos: { left: "20.1%", top: "21%" },
        size: 15,
        states: [
          {
            id: 0,
            img: "/co2/muell_03.png",
            label: "Einen. Kommt eh alles auf eine Halde.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/muell_02.png",
            label: "Zwei. Gelber Sack und Restmüll.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/muell_01.png",
            label: "Mehrere Säcke, wobei Papier sammele ich so.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
      {
        popupText:
          "Was gibt es schöneres, als in ein warmes Zuhause zu kommen. Doch muss dafür die Heizung die ganze Zeit an sein?",
        name: "Heizung",
        pos: { left: "70.1%", top: "14%" },
        size: 15,
        states: [
          {
            id: 0,
            img: "/co2/heizung_03.png",
            label:
              "Ich liebe es, wenn ich in ein warmes Zuhause komme. Bei mir sind immer alle Heizungen an und die Türen offen.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/heizung_02.png",
            label:
              "Die Heizung ist aus, wenn ich aus dem Haus gehe. Aber wenn ich nach Hause komme, dann drehe ich erstmal alle voll auf, damit es schnell warm wird.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/heizung_01.png",
            label:
              "Die Heizung ist eh nur in wenigen Zimmern an – und dort maximal auf drei. Zudem habe ich alle Türen zu.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
    ],
    arrows: [
      { img: "pl", room: "bad" },
      { img: "pr", room: "schlafzimmer" },
      { img: "pd", room: "kueche" },
    ],
    charPos: { left: "50%", top: "50%" },
    name: "Flur",
    description:
      "Willkommen in Deinem zu Hause. Oder bist Du gerade auf dem Sprung nach draußen? Der erste und letzte Raum, wenn Du durch die Wohnungstür schreitest. Hast Du an alles gedacht, um in eine gesunde Welt hinauszugehen?",
  },
  bad: {
    items: [
      {
        popupText:
          "Saubere Hände und viel mehr. Aber es macht einen Unterschied, wie wir uns waschen.",
        name: "waschen",
        pos: { left: "14.1%", top: "63%" },
        size: 13,
        states: [
          {
            id: 0,
            img: "/co2/Wasserverbrauch03.png",
            label:
              "Dusche oder Bad, Hauptsache heiß und bis die Haut schrumpelig ist.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/Wasserverbrauch02.png",
            label: "Ich dusche nur, Baden verbraucht zu viel Wasser.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/Wasserverbrauch01.png",
            label:
              "Ich mache das Wasser nur an, wenn ich es auch wirklich brauche.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
      {
        popupText:
          "Wir können uns noch so anstrengen, aber am Abend sind in der Regel nicht nur die Socken ein Fall für die Waschmaschine. Und dann?",
        name: "waschmaschine",
        pos: { left: "74.1%", top: "59%" },
        size: 12,
        states: [
          {
            id: 0,
            img: "/co2/Textilien03.png",
            label: "Abends kommt immer alles in die Maschine.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/Textilien02.png",
            label: "Wenn verschmutzt, dann in die Waschmaschine.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/Textilien01.png",
            label:
              "Ich wasche nur, wenn es nötig ist. Und dann nur auf niedriger Temperatur.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
      {
        popupText:
          "Was macht uns „schöner“, und wie viel Chemie und Mikroplastik steckt in Produkten, die du für Deinen Körper nutzt?",
        name: "makeup",
        pos: { left: "34.1%", top: "7%" },
        size: 13,
        states: [
          {
            id: 0,
            img: "/co2/Mikroplastik03.png",
            label: "Egal, Hauptsache schön.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/Mikroplastik02.png",
            label: "Nur Produkte die frei von Mikroplastik sind.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/Mikroplastik01.png",
            label: "Ohne Apps wie ToxFox kaufe ich nichts.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
    ],
    arrows: [{ img: "pr", room: "flur" }],
    charPos: { left: "20%", top: "45%" },
    name: "Bad",
    description:
      "Für viele Menschen ist der erste Gang nach dem Aufstehen der ins Bad. Aber auch wenn Ihr euch den Schlaf noch nicht aus den Augen gewaschen habt, könnt Ihr schon hier Euren Beitrag für mehr Klimaschutz leisten.",
  },
  wohnzimmer: {
    items: [
      {
        popupText:
          "Kein Abend ohne Süßigkeiten. Snacken und Sofa, das gehört doch zusammen – oder wer geht ins Kino und gönnt sich kein Popcorn.",
        name: "snacks",
        pos: { left: "45.1%", top: "69%" },
        size: 8,
        states: [
          {
            id: 0,
            img: "/co2/essen_03.png",
            label: "Es muss crunchen und schmecken.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/essen_02.png",
            label:
              "Wenn schon Schokolade und Nüsse, dann aus fairem und biologischem Anbau.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/essen_01.png",
            label: "Saisonales Obst vom Markt.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
      {
        popupText:
          "Fernseher, Radio, Spielkonsole: Was ist bei Euch „immer“ an?",
        name: "spuema",
        pos: { left: "45.1%", top: "45%" },
        size: 13,
        states: [
          {
            id: 0,
            img: "/co2/tv_an.png",
            label: "Irgendwas ist immer an.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/tv_standby.png",
            label: "Aus ist aus – aber der Stecker bleibt drinnen.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/tv_aus.png",
            label:
              "Mehrfachstecker mit Schalter. Bei mir ist nichts auf Standby.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
      {
        popupText:
          "Feuer hat was Faszinierendes. Dann wird es daheim auch gleich viel wohliger – und die Klimabilanz?",
        name: "feuer",
        pos: { left: "40.1%", top: "17%" },
        size: 7,
        states: [
          {
            id: 0,
            img: "/co2/holz_03.png",
            label: "Holz ist doch CO2-Neutral.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/holz_02.png",
            label: "Ein Filter reduziert die Feinstaubbelastung.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/holz_01.png",
            label:
              "Wenn dann nur aus regionalen Beständen in einer modernen Feuerstätte.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
    ],
    arrows: [{ img: "pr", room: "kueche" }],
    charPos: { left: "25%", top: "66%" },
    name: "Wohnzimmer",
    description:
      "Egal ob Couchpotato, Serienjunkie oder Leseratte. Im Wohnzimmer hat jeder seine ganz eigenen Vorlieben. Aber alle eint, dass es auch hier Hebel und Möglichkeiten gibt, um das Klima zu schützen.",
  },
  kueche: {
    items: [
      {
        popupText:
          "Essen, so heißt es, hält Leib und Seele zusammen. Aber was ist noch wichtig:",
        name: "Essen",
        pos: { left: "46.1%", top: "55%" },
        size: 8,
        states: [
          {
            id: 0,
            img: "/co2/essen_03.png",
            label: "Viel Fleisch gehört auf den Teller.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/essen_02.png",
            label: "Hauptsache frisch: Wo das Essen herkommt, ist egal.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/essen_01.png",
            label: "Lebensmittel sollten regional und bio sein.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
      {
        popupText:
          "Reinigungsmittel gelangen dann nach getaner Arbeit ins Abwasser. Worauf solltet Ihr deswegen achten:",
        name: "Reinigung",
        pos: { left: "85.1%", top: "16%" },
        size: 5,
        states: [
          {
            id: 0,
            img: "/co2/reinigungsmittel_03.png",
            label: "Auf nichts.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/reinigungsmittel_01.png",
            label: "Produkte sollten biologisch abbaubar sein.",
            points: 20,
          },
          {
            id: 2,
            img: "/co2/reinigungsmittel_02.png",
            label: "Die Verpackung sollte recyclingfähig sein.",
            points: 10,
          },
        ],
        defaultState: 0,
      },
      {
        popupText:
          "Spülmaschine, Leitungswasser, Lebensmittel säubern: Nicht jeder Wasserverbrauch ist auch wirklich sofort ersichtlich, oder?",
        name: "abspuelen",
        pos: { left: "71.1%", top: "15%" },
        size: 13,
        states: [
          {
            id: 0,
            img: "/co2/waschbecken_03.png",
            label: "Quatsch, das Wasser kommt unbegrenzt aus dem Hahn.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/waschbecken_01.png",
            label: "Ich achte auf den digitalen Wasserabdruck.",
            points: 20,
          },
          {
            id: 2,
            img: "/co2/waschbecken_02.png",
            label: "Spülmaschine & Co laufen nur im „Öko-Modus“.",
            points: 10,
          },
        ],
        defaultState: 0,
      },
    ],
    arrows: [
      { img: "pu", room: "flur" },
      { img: "pl", room: "wohnzimmer" },
      { img: "pd", room: "balkon" },
    ],
    charPos: { left: "66%", top: "46%" },
    name: "Küche",
    description:
      "Die Küche ist nicht nur auf einer guten Party der wichtigste Raum in jeder Wohnung. Hier gibt es viele Dinge, die Du machen kannst, um die Welt zu einem besseren Ort zu machen und das Klima zu schützen.",
  },
  balkon: {
    items: [
      {
        popupText:
          "Es gibt nichts schöneres, als bei einem schönen Tag mit Freunden und Familie auf Terrasse und Balkon zu sitzen. Doch auf was landen Eure 4 Buchstaben denn eigentlich?",
        name: "terasssse",
        pos: { left: "40.1%", top: "39%" },
        size: 20,
        states: [
          {
            id: 0,
            img: "/co2/Gartenmoebel03.png",
            label: "Wir haben Gartenmöbel aus hochwertigem Tropenholz.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/Gartenmoebel02.png",
            label: "Plastikstühle reichen völlig.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/Gartenmoebel01.png",
            label:
              "Holzmöbel, aus europäischer und nachhaltiger Forstwirtschaft.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
      {
        popupText:
          "Pflanzen sind schön und können Lebensraum für zahlreiche nützliche Tiere. Aber in was habt Ihr Eure grünen Freunde eigentlich getopft?",
        name: "erden",
        pos: { left: "76.1%", top: "72%" },
        size: 13,
        states: [
          {
            id: 0,
            img: "/co2/Erde03.png",
            label: "In Erde, was sonst.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/Erde02.png",
            label: "Kokosfasern statt Torf.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/Erde01.png",
            label: "Ich kaufe nur torffreie Produkte ohne „Zusatzstoffe“.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
      {
        popupText:
          "Pflanzen: Sie sehen nicht nur schön aus, sie können auch der Umwelt helfen.",
        name: "planties",
        pos: { left: "25.1%", top: "71%" },
        size: 22,
        states: [
          {
            id: 0,
            img: "/co2/Pflanzen03.png",
            label: "Draußen, da steht nur Altglas und der Aschenbecher.",
            points: 0,
          },
          {
            id: 1,
            img: "/co2/Pflanzen02.png",
            label: "Blühpflanzen und Gemüse – das gehört einfach dazu.",
            points: 10,
          },
          {
            id: 2,
            img: "/co2/Pflanzen01.png",
            label: "Pflanzen sind für mich und für Insekten.",
            points: 20,
          },
        ],
        defaultState: 0,
      },
    ],
    arrows: [{ img: "pu", room: "kueche" }],
    charPos: { left: "66%", top: "46%" },
    name: "Balkon",
    description:
      "Der Balkon kann ein wunderbarer Ort sein, um das Klima zu schützen und gleichzeitig eine angenehme Umgebung für dich selbst zu schaffen.",
  },
};
export default function Co2() {
  let { character } = useUser();
  let nav = useNavigate();
  let { states, setStates, currentRoom, setCurrentRoom } = useCo2State();

  let [selected, setSelected] = useState(null);
  console.log(rooms, currentRoom);
  let selectedItem =
    rooms[currentRoom].items.find((i) => i.name === selected) || null;

  return (
    <div className="co2">
      <div
        className="closeButton"
        onClick={() => {
          nav("/");
        }}
      >
        <FaTimes></FaTimes>
      </div>
      <div
        className="room"
        style={{
          backgroundImage: "url(/co2/" + currentRoom + ".png)",
        }}
      >
        {currentRoom === "flur" && (
          <div
            className={"pfeil pu"}
            onClick={() => {
              nav("/co2result");
            }}
          >
            <img alt="navigation arrow" src={"/co2/pu.gif"}></img>
          </div>
        )}
        {rooms[currentRoom].arrows.map((a) => {
          return (
            <div
              className={"pfeil " + a.img}
              onClick={() => {
                setSelected(null);
                setCurrentRoom(a.room);
              }}
            >
              <img alt="navigation arrow" src={"/co2/" + a.img + ".gif"}></img>
            </div>
          );
        })}
        {rooms[currentRoom].items.map((i) => {
          return (
            <div className="itemWrapper" style={i.pos}>
              <img
                onClick={() => setSelected(selected === i.name ? null : i.name)}
                style={{ width: i.size + "vw" }}
                className="itemImg"
                alt="clickable item"
                src={
                  i.states[
                    states[i.name] >= 0 ? states[i.name] : i.defaultState
                  ].img
                }
              ></img>
            </div>
          );
        })}
        <div className="inGameCharacter" style={rooms[currentRoom].charPos}>
          <img
            alt="Character icon"
            src={"/characters/" + character + ".png"}
          ></img>
        </div>
      </div>
      <div className="gameText">
        <div className="title">{rooms[currentRoom].name}</div>
        <div>{rooms[currentRoom].description}</div>
      </div>
      {selectedItem && (
        <div className="questionPopup">
          <b>{selectedItem.popupText}</b>
          <Spacer h={12}></Spacer>
          {selectedItem.states.map((s) => {
            let isSelected =
              (states[selectedItem.name] >= 0
                ? states[selectedItem.name]
                : selectedItem.defaultState) === s.id;
            return (
              <div
                onClick={() => {
                  setStates({ ...states, [selectedItem.name]: s.id });
                }}
                className={"itemOption " + (isSelected ? "selected" : "")}
              >
                {s.label}
              </div>
            );
          })}
          <div
            className="doneButton"
            onClick={() => {
              setSelected(null);
            }}
          >
            Fertig
          </div>
        </div>
      )}
    </div>
  );
}
