import React from "react";
import Layout from "../../atoms/Layout/Layout";
import { DifficultySelector } from "../Forscher/Forscher";
import "./ForscherDifficulty.scss";

export default function ForscherDifficulty() {
  return (
    <Layout className="ForscherDifficulty">
      <DifficultySelector />
    </Layout>
  );
}
