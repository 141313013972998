import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ActionLink from "../../atoms/ActionLink/ActionLink";
import Button from "../../atoms/Button/Button";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import "./Forscher2.scss";

export default function Forscher2() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Layout>
      <QuestionAnswer />
    </Layout>
  );
}

function QuestionAnswer() {
  return (
    <div className="questionAnswer">
      <div className="img">
        <img alt="an elephant" src="/elefant.png" />
      </div>
      <div className="flex1"></div>
      <div className="questionNumber">Frage 1</div>
      <div className="flex1"></div>
      <div className="question">Welche Farbe hat das Tier?</div>
      <Spacer h={12}></Spacer>
      <div className="flex1"></div>
      <div className="answers">
        <Button href="/forscher/result" rotate flex>
          Rot
        </Button>
        <Button href="/forscher/result" rotate flex>
          Grün
        </Button>
        <Button href="/forscher/result" rotate flex>
          Gelb
        </Button>
      </div>
      <div className="flex1"></div>
      <Spacer h={24} />
      <div className="flex">
        <ActionLink onClick={() => window.history.back()}>Zurück</ActionLink>
        <ActionLink href={"/forscher/result"}>Ich weiss es nicht</ActionLink>
      </div>
    </div>
  );
}

export function DifficultySelector() {
  return (
    <div className="difficultySelector">
      <div className="top">
        <div className="title">Bist du Experte?</div>
        <div className="text">
          Hier wählst Du den richtigen Schwierigkeitsgrad für das folgende Spiel
          aus.
        </div>
      </div>
      <div className="bottom">
        <div className="left">
          <div className="img">
            <img src="/smol.jpg" alt="" />
          </div>
          <div className="title">Neuling</div>
          <div className="text">
            Für unsere Frischelinge! Extra einfache Fragemuster, damit auf
            unsere jüngsten Besucher Spaß haben!
          </div>
          <div className="flex1"></div>
          <Button href="/forscher/frage" rotate>
            Neu im
            <br />
            Dschungel
          </Button>
        </div>
        <div className="left">
          <div className="img">
            <img src="/big.jpg" alt="" />
          </div>
          <div className="title">Experte</div>
          <div className="text">
            Du bist Erfahrener Expeditör und hast richtig Lust zu Forschen? Dann
            bist Du hier genau richtig!
          </div>
          <div className="flex1"></div>
          <Button href="/forscher/frage" green rotate>
            Tropen-Experte
          </Button>
        </div>
      </div>
      <Spacer h={24} />
      <ActionLink>Zurück</ActionLink>
    </div>
  );
}
