import React from "react";
import { useNavigate } from "react-router-dom";
import ActionLink from "../../atoms/ActionLink/ActionLink";
import Button from "../../atoms/Button/Button";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import TrumpCard from "../../atoms/TrumpCard/TrumpCard";
import { useUser } from "../../zustand/useUser";
import "./Result.scss";

export default function Result() {
  return <Layout className="Result"></Layout>;
}

export function AnswerScreen({ animals }) {
  let { addToCollection } = useUser();
  let nav = useNavigate();
  return animals.length === 1 ? (
    <div className="padding">
      <div className="title center big">Ist es dieses Tier?</div>
      <Spacer h={12} />

      <TrumpCard
        data={animals[0]}
        onClick={() => {
          addToCollection(animals[0].id);
          nav("/sammlung");
        }}
      ></TrumpCard>
      <Spacer h={24} />

      <Button
        onClick={() => {
          addToCollection(animals[0].id);
          nav("/sammlung");
        }}
      >
        Richtig
      </Button>
      <Spacer h={24} />
      <ActionLink onClick={() => window.location.reload()}>
        Neu starten
      </ActionLink>
    </div>
  ) : (
    <div className="padding">
      <div className="title center big">Bitte wähle dein Tier aus!</div>
      <Spacer h={12} />
      {animals.map((a) => {
        return (
          <>
            <TrumpCard
              data={a}
              onClick={() => {
                addToCollection(a.id);
                nav("/sammlung");
              }}
            ></TrumpCard>
            <Spacer h={24} />
          </>
        );
      })}
      <Spacer h={24} />
      <ActionLink onClick={() => window.location.reload()}>
        Neu starten
      </ActionLink>
    </div>
  );
}
