import React from "react";
import { useNavigate } from "react-router-dom";
import ThreeSixtyView from "../../atoms/ThreeSixtyView/ThreeSixtyView";
import "./Kambodscha2.scss";

export default function Kambodscha2() {
  let nav = useNavigate();
  return (
    <ThreeSixtyView
      type="video"
      source="/v.mp4"
      points={[
        {
          position: [1000, 100, -2000],
          hoverText: "Ich bin 1 punkt",

          onClick: () => {
            nav("/kambodscha")
          },
        },
      ]}
    ></ThreeSixtyView>
  );
}
