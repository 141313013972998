import React from "react";
import { useNavigate } from "react-router-dom";
import "./Button.scss";

export default function Button({
  children,
  green,
  rotate,
  big,
  flex,
  href,
  onClick,
  className,
  disabled,
}) {
  let nav = useNavigate();
  return (
    <div
      disabled={disabled}
      className={
        "Button " +
        className +
        " " +
        (green ? "green " : " ") +
        (rotate ? "rotate " : " ") +
        (big ? "big " : " ") +
        (flex ? "flex1 " : " ")
      }
      onClick={() => {
        !disabled && href && nav(href);
        !disabled && onClick && onClick();
      }}
    >
      {children}
    </div>
  );
}
