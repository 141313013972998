import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Nav from "../Nav/Nav";
import "./Layout.scss";

export default function Layout({ children, navHidden }) {
  useEffect(() => {
    //scroll to top on page change
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  let [nav, setNav] = useState(false);
  return (
    <div className="Layout">
      <Nav open={nav} onClose={() => setNav(false)}></Nav>
      <Header navHidden={navHidden} showNav={() => setNav(true)} />
      {children}
    </div>
  );
}
