import create from "zustand";
import { persist } from "zustand/middleware";

export const useUser = create(
  persist(
    (set, get) => ({
      collection: [],
      difficulty: null,
      character: "elefant",
      vrmode: null,
      setVrMode: (vrmode) => set({ vrmode }),
      setDifficulty: (difficulty) => set({ difficulty }),
      setCharacter: (character) => set({ character }),
      addToCollection: (id) =>
        set((state) => ({
          collection: [...state.collection.filter((e) => e !== id), id],
        })),
    }),
    {
      name: "user-storage223", // unique name
    }
  )
);
