import React from "react";
import CharacterSelector from "../../atoms/CharacterSelector/CharacterSelector";
import Layout from "../../atoms/Layout/Layout";
import "./Co2character.scss";

export default function Co2character() {
  return (
    <Layout className="Co2character">
      <CharacterSelector></CharacterSelector>
    </Layout>
  );
}
