import React from "react";
import Button from "../../atoms/Button/Button";
import Layout from "../../atoms/Layout/Layout";
import "./Spiele.scss";

export default function Spiele() {
  return (
    <Layout className="Spiele">
      <div className="apps">
        <div className="app">
          <div className="img">
            <img
              alt="happy people at the zoo"
              src="./ACCB Kambodscha.JPG"
            ></img>
            <div className="credit">Foto: ACCB Kambodscha</div>
          </div>
          <div className="title">
            DAS ACCB - <br></br>
            Münsters artenschutzzentrum in Kambodscha
          </div>
          <div className="text">
            Das Angkor Centre for Conservation of Biodiversity (ACCB) ist das
            Artenschutzzentrum des Allwetterzoos in Kambodscha. Hier engagieren
            sich rund 40 Mitarbeitende für den Erhalt kritisch bedrohter Arten.
            Im ACCB werden vor allem Vögel und Schildkröten gehalten und
            gezüchtet sowie beschlagnahmte oder verletzte Wildtiere aufgenommen
            (Auffangstation). Zudem werden Einheimische in Bildungsprojekten für
            den Schutz bedrohter Arten und ihrer Lebensräume sensibilisiert. Das
            ACCB führt darüber hinaus mit diversen Partnerorganisationen
            Schutzprojekte vor Ort durch, z. B. Wiederansiedlungen verschiedener
            Tierarten.<br></br>
            <br></br>
            Komm‘ mit uns auf einen virtuellen Rundgang durch das ACCB. Entdecke
            die Arten, um die wir uns kümmern und lerne unsere Projekte kennen.
          </div>
          <div className="flex1"></div>
          <Button href={"/kambodschaControls"} green rotate>
            Erlebe das ACCB
          </Button>
        </div>
        <div className="app">
          <div className="img">
            <img alt="a giraffe" src="./bestimm.jpg"></img>
          </div>
          <div className="title">ENTDECKE DIE TIERWELT DER MERANTI-HALLE</div>
          <div className="text">
            In der Meranti-Halle gibt es viel zu entdecken. Quirlige Tiere, bunte
            Pflanzen und vieles mehr. Findest du alle unsere tierischen
            Bewohner? Die Forscher-App hilft dir dabei, deine Entdeckungen
            richtig zu bestimmen. Auf geht's.
          </div>
          <div className="flex1"></div>
          <Button green rotate href="/forscher/frage">
            Hier geht’s zur Forscher-App!
          </Button>
        </div>
      </div>
      <div className="apps">
        <div className="app">
          <div className="img">
            <img alt="donkeys" src="./co2prev.jpg"></img>
          </div>
          <div className="title">SEI EIN KLIMA-HELD</div>
          <div className="text">
            Klimaschutz betrifft jeden und jeder kann auch etwas dafür tun.
            Glaubst Du nicht? Wir beweisen es dir. Storyboard! Willkommen. Ein
            neuer ereignisreicher Tag steht an - ein Tag, an dem du wieder ein
            echter Klima-Held sein kannst. Besuche alle fünf Räume, schau dich
            um checke, mit welchen Kleinigkeiten du den Unterschied machen
            kannst. Die Konsequenzen deines Handelns siehst du dann, wenn du vor
            die Haustür gehst.
          </div>
          <div className="flex1"></div>
          <Button green rotate href="/co2">
            Hier geht’s zur Nachhaltigkeits-App!
          </Button>
        </div>
        <div className="app">
          <div className="img">
            <img alt="a white horse" src="./baumapp.jpg"></img>
          </div>
          <div className="title">WER LEBT WO?</div>
          <div className="text">
            Wie ein Haus, kann auch ein Wald verschiedene Stockwerke haben. In
            tropischen Regenwäldern als Hotspots der Artenvielfalt, leben
            besonders viele Pflanzen und Tiere in den verschiedenen Stockwerken.
            Steig ein in unseren digitalen Fahrstuhl und findet herausfinden,
            wer wo zu finden ist.
          </div>
          <div className="flex1"></div>
          <Button green rotate href="/turmControls">
            Los geht die Fahrt!
          </Button>
        </div>
      </div>{" "}
    </Layout>
  );
}
