import React, { useState } from "react";
import ActionLink from "../../atoms/ActionLink/ActionLink";
import Button from "../../atoms/Button/Button";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import "./Forscher.scss";

import data from "../../data";
import { AnswerScreen } from "../Result/Result";
import { useUser } from "../../zustand/useUser";

function getAnimalsLeft(animals, answers) {
  return animals.filter((a) => {
    let isOk = true;
    answers.forEach((ans) => {
      if (a.answers[ans.question] !== ans.answer) {
        isOk = false;
      }
    });
    return isOk;
  });
}

function scoreQuestions(questions, animals) {
  let sortedQuestions = questions.map((q) => {
    let score = { options: {} };
    //rank each answer by how many animals are still left
    q.options.forEach((option) => {
      score.options[option.id] = getAnimalsLeft(animals, [
        { question: q.id, answer: option.id },
      ]).length;
    });
    let sortedScore = Object.values(score.options).filter((a) => a).length;
    return {
      ...q,
      score: sortedScore.length < 2 ? -1 : sortedScore,
    };
  });
  return sortedQuestions.sort((a, b) => b.score - a.score);
}

export default function Forscher() {
  let { difficulty, setDifficulty } = useUser();
  let [answers, setAnswers] = useState([]);
  let [skipped, setSkipped] = useState([]);
  console.log(data, answers);

  //what animals are still left?
  let animalsLeft = getAnimalsLeft(data.lebewesen, answers);

  //What questions are still left?

  //filter skipped
  let questionsLeft = data.fragen.filter((f) => !skipped.includes(f.id));
  //filter already answered
  let answeredIds = answers.map((a) => a.question);
  questionsLeft = questionsLeft.filter((f) => !answeredIds.includes(f.id));

  //filter adultOnly==true for kids.
  if (difficulty === "easy") {
    questionsLeft = questionsLeft.filter((f) => f.adultOnly !== true);
  }

  //Sort questions by how well they fit with the animals left.
  questionsLeft = scoreQuestions(questionsLeft, animalsLeft);
  console.log({ data, answers, questionsLeft, animalsLeft });

  return (
    <Layout>
      {!difficulty && <DifficultySelector setDifficulty={setDifficulty} />}
      {questionsLeft.length > 0 && difficulty && (
        <>
          {animalsLeft.length > (difficulty === "hard" ? 1 : 3) && (
            <QuestionAnswer
              question={questionsLeft[0]}
              animals={animalsLeft}
              questions={questionsLeft}
              setAnswer={(o) => setAnswers([...answers, o])}
              skip={(o) => setSkipped([...skipped, o])}
            ></QuestionAnswer>
          )}
          {animalsLeft.length <= (difficulty === "hard" ? 1 : 3) && (
            <AnswerScreen animals={animalsLeft}></AnswerScreen>
          )}
        </>
      )}
      {questionsLeft.length === 0 && animalsLeft.length === 0 && (
        <div className="box" style={{ textAlign: "center" }}>
          <Spacer h={24}></Spacer>
          Das Tier konnte nicht gefunden werden.
          <Spacer h={24}></Spacer>
          <ActionLink href="/forscher/frage">Neu starten</ActionLink>
        </div>
      )}
      {questionsLeft.length === 0 && animalsLeft.length > 0 && (
        <AnswerScreen animals={animalsLeft}></AnswerScreen>
      )}
    </Layout>
  );
}

function QuestionAnswer({ question, setAnswer, skip, animals, questions }) {
  return (
    <div className="questionAnswer">
      <div className="img">
    
      </div>
      <div className="flex1"></div>
      <div className="questionNumber">Frage 1</div>
      <div className="flex1"></div>
      <div className="question">{question.text}</div>
      <Spacer h={12}></Spacer>
      <div className="flex1"></div>
      <div className="answers">
        {question.options.map((a) => {
          let score = getAnimalsLeft(animals, [
            { question: question.id, answer: a.id },
          ]).length;
          return (
            <Button
              onClick={() => setAnswer({ question: question.id, answer: a.id })}
              rotate
              flex
              className={"score" + score}
              disabled={score === 0}
            >
              {a.text}
            </Button>
          );
        })}
      </div>
      <div className="flex1"></div>
      <Spacer h={24} />
      <div className="flex">
        <ActionLink onClick={() => skip(question.id)}>
          Ich weiss es nicht
        </ActionLink>
      </div>
    </div>
  );
}

export function DifficultySelector({ setDifficulty }) {
  return (
    <div className="difficultySelector">
      <div className="top">
        <div className="title">Bist du Experte?</div>
        <div className="text">
          Hier wählst Du den richtigen Schwierigkeitsgrad für das folgende Spiel
          aus.
        </div>
      </div>
      <div className="bottom">
        <div className="left">
          <div className="img">
            <img src="/smol.jpg" alt="" />
          </div>
          <div className="title">Neuling</div>
          <div className="text">
            Für unsere Frischelinge! Extra einfache Fragemuster, damit auf
            unsere jüngsten Besucher Spaß haben!
          </div>
          <div className="flex1"></div>
          <Button onClick={() => setDifficulty("easy")} rotate>
            Neu im
            <br />
            Dschungel
          </Button>
        </div>
        <div className="left">
          <div className="img">
            <img src="/big.jpg" alt="" />
          </div>
          <div className="title">Experte</div>
          <div className="text">
            Du bist Erfahrener Expeditör und hast richtig Lust zu Forschen? Dann
            bist Du hier genau richtig!
          </div>
          <div className="flex1"></div>
          <Button onClick={() => setDifficulty("hard")} green rotate>
            Tropen-Experte
          </Button>
        </div>
      </div>
      <Spacer h={24} />
      <ActionLink>Zurück</ActionLink>
    </div>
  );
}
