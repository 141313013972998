import React from "react";
import { useNavigate } from "react-router-dom";
import "./ActionLink.scss";

export default function ActionLink({ children, onClick = () => {}, href }) {
  let nav = useNavigate();
  return (
    <div className="ActionLink" onClick={() => (href ? nav(href) : onClick())}>
      {children}
    </div>
  );
}
