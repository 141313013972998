import React from "react";
import { useUser } from "../../zustand/useUser";
import Button from "../Button/Button";
import "./CharacterSelector.scss";

export default function CharacterSelector() {
  let { character, setCharacter } = useUser();
  return (
    <div className="CharacterSelector">
      <div className="title">Wähle deine Spielfigur</div>
      <div
        onClick={() => setCharacter("otter")}
        className={"character " + (character === "otter" ? "active " : " ")}
      >
        <div className="avatar">
          <img alt="Character avatar" src="/characters/otter.png"></img>
        </div>
        <div className="name">Otter</div>
      </div>
      <div
        onClick={() => setCharacter("dino")}
        className={"character " + (character === "dino" ? "active " : " ")}
      >
        <div className="avatar">
          <img alt="Character avatar" src="/characters/dino.png"></img>
        </div>
        <div className="name">Dino</div>
      </div>
      <div
        onClick={() => setCharacter("tapir")}
        className={"character " + (character === "tapir" ? "active " : " ")}
      >
        <div className="avatar">
          <img alt="Character avatar" src="/characters/tapir.png"></img>
        </div>
        <div className="name">Tapir</div>
      </div>
      {character && <Button href="/co2game">Weiter!</Button>}
    </div>
  );
}
