import React, { useEffect, useRef, useMemo } from "react";
import * as PANOLENS from "panolens";
import { usePano } from "../Turm/Turm";
import * as THREE from "three";
import defaultIcon from "./defaultIcon";
const Pano = ({ video, points, children, level }) => {
  let { sensorMode, reticle } = usePano();
  let viewer = useRef();

  const panorama = useMemo(() => {
    let p = new PANOLENS.VideoPanorama(video, {
      autoplay: true,
      loop: true,
      muted: true,
    });
    points.forEach((poi) => {
      const infospot = new PANOLENS.Infospot(
        poi.scale * 100 || 100,
        poi.icon || defaultIcon,
        true
      );
      infospot.position.set(poi.position[0], poi.position[1], poi.position[2]);
      infospot.addHoverText(poi.text);
      infospot.addEventListener("click", () => {
        poi.onClick();
        if (!poi.isNav) {
          new THREE.TextureLoader().load("/test2.png", (texture) => {
            //Update Texture
            infospot.material.map = texture;
            infospot.material.needsUpdate = true;
          });
        }
      });
      p.add(infospot);
    });

    console.log("memo");
    return p;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);
  useEffect(() => {
    console.log("effect1");
    viewer.current = new PANOLENS.Viewer({
      enableReticle: false,
      autoHideInfospot: false,
      controlButtons: [],
    });
    viewer.current.reticle.setColor(0x77ffff);

    // Add to left panel

    viewer.current.add(panorama);

    return () => {
      viewer.current.remove(panorama);
      viewer.current.destroy();
      let container = viewer.current.getContainer();
      container.parentElement.removeChild(container);
      document.querySelector("#panolens-style-addon") &&
        document.querySelector("#panolens-style-addon").remove();
    };
  }, [panorama, video]);
  useEffect(() => {
    console.log("effect2");
    if (reticle) {
      viewer.current.enableReticleControl();
    } else {
      viewer.current.disableReticleControl();
    }
  }, [reticle, viewer, level]);
  useEffect(() => {
    console.log("effect3");
    if (sensorMode) {
      viewer.current.enableControl(1);
    } else {
      viewer.current.enableControl(0);
    }
  }, [sensorMode, viewer, level]);
  /*useEffect(() => {
    const panorama = new PANOLENS.VideoPanorama("/v.mp4", {
      autoplay: true,
      loop: true,
    });
    viewer.add(panorama);

    console.log("IMMG!");

    return () => {
      viewer.remove(panorama);
      viewer.destroy();
    };
  }, [ref, viewer]);
  */
  return <>{children(viewer)}</>;
};

export default Pano;
