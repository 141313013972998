import React from "react";
import { useNavigate } from "react-router-dom";
import ActionLink from "../../atoms/ActionLink/ActionLink";
import Button from "../../atoms/Button/Button";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import { usePano } from "../Turm/Turm";
import "./TurmControls.scss";

export default function TurmControls() {
  return (
    <Layout className="TurmControls">
      <DifficultySelector nextUrl="/turm/1"></DifficultySelector>
    </Layout>
  );
}

export function DifficultySelector({ setDifficulty, nextUrl }) {
  let { setSensorMode, setReticle } = usePano();
  let nav = useNavigate();
  return (
    <div className="difficultySelector">
      <div className="top">
        <div className="title">Wie willst du Spielen?</div>
        <div className="text">
          Hier wählst Du wie du das folgende Spiel steuern möchtest.
        </div>
      </div>
      <div className="bottom">
        <div className="left">
          <div className="title">Klassisch</div>
          <div className="text">Nutze deine Finger um dich umzusehen.</div>
          <div className="flex1"></div>
          <Button
            onClick={() => {
              setSensorMode(false);
              setReticle(false);
              nav(nextUrl);
            }}
            rotate
          >
            Klassischer Modus
          </Button>
        </div>
        <div className="left">
          <div className="title">AR-Modus</div>
          <div className="text">Bewege dein Handy um dich umzusehen.</div>
          <div className="flex1"></div>
          <Button
            onClick={() => {
              if (
                DeviceMotionEvent &&
                DeviceMotionEvent.requestPermission &&
                typeof DeviceMotionEvent.requestPermission === "function"
              ) {
                DeviceMotionEvent.requestPermission().then(() => {
                  setSensorMode(true);
                  setReticle(true);
                  nav(nextUrl);
                });
              } else {
                setSensorMode(true);
                setReticle(true);
                nav(nextUrl);
              }
            }}
            green
            rotate
          >
            AR-Modus
          </Button>
        </div>
      </div>
      <Spacer h={24} />
      <ActionLink onClick={() => nav(-1)}>Zurück</ActionLink>
    </div>
  );
}
