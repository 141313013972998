import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../atoms/Layout/Layout";
import { useCo2State } from "../../zustand/useCo2State";
import { calculateScore, maxPoints, rooms } from "../Co2/Co2";
import "./Co2Result.scss";

export default function Co2Result() {
  let { states } = useCo2State();
  let score = calculateScore(rooms, states);
  let percentScore = (score / maxPoints) * 100;
  let nav = useNavigate();

  let video = "welt_gut.mp4";
  let title = "Erfolg";
  let text = (
    <>
      Sehr gut. Du weißt, was Du machen musst, um die Umwelt zu entlasten. Es
      gibt aber noch viele weitere Möglichkeiten. Wusstest Du z.B., dass eine
      E-Mail ein Jahr lang aufzubewahren in etwa dem Einschalten einer
      25W-Glühbirne für 30min (Quelle; ADEME, Greenpeace) entspricht. Also:
      Postfach ausmisten!
    </>
  );
  if (percentScore < 70) {
    video = "welt_mittel.mp4";
    title = "Ausbaufähig";
    text = (
      <>
        Du bist auf dem richtigen Weg. Aber Du triffst noch immer
        Entscheidungen, für die es eine bessere Lösung gibt, die der Umwelt, und
        damit auch Dir helfen. Versuche es doch gleich noch einmal. Du wirst
        sehen, es kann ganz einfach sein.
      </>
    );
  }
  if (percentScore < 30) {
    title = "Schade.";
    video = "welt_schlecht.mp4";
    text = (
      <>
        Deine Entscheidungen waren vielleicht für Dich gut und sinnvoll, nicht
        aber für die Umwelt – und damit am Ende dann doch schlecht für Dich.
        Versuch es doch gleich noch einmal und überdenke Deine Entscheidungen.
        Du wirst sehen, es kann ganz einfach sein.
      </>
    );
  }

  return (
    <Layout className="Co2Result">
      <video
        className="spinningEarth"
        playbackRate={2}
        src={"/co2/" + video}
        playsInline
        muted
        loop
        autoPlay
      ></video>
      <div className="gameText">
        <div className="title">{title}</div>
        <div className="status">
          <div
            className="statusBar"
            style={{ width: percentScore + "%" }}
          ></div>
        </div>
        <div>{text}</div>
        <br></br>
        <div className="tryAgain" onClick={() => nav("/co2game")}>
          Weiter probieren
        </div>
      </div>
    </Layout>
  );
}
