import React from "react";
import ActionLink from "../../atoms/ActionLink/ActionLink";
import Button from "../../atoms/Button/Button";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import "./TurmEntry.scss";

export default function TurmEntry() {
  return (
    <Layout className="TurmEntry">
      <DifficultySelector></DifficultySelector>
    </Layout>
  );
}

export function DifficultySelector({ setDifficulty }) {
  return (
    <div className="difficultySelector">
      <div className="top">
        <div className="title">Willkommen im Turm!</div>
        <div className="text">
          Entdecke die Vielfalt der Tiere im Merantibaum!
        </div>
      </div>
      <div className="bottom">
        <div className="left">
          <div className="img">
            <img
              src="https://static.educalingo.com/img/ms/800/meranti.jpg"
              alt=""
            />
          </div>
          <div className="title">Entdeckertour</div>
          <div className="text">
            Kannst du alle verstecken Tiere im Merantibaum finden? Begib dich
            auf eine magische Reise in's Dickicht des Jungels.
          </div>
          <div className="flex1"></div>
          <Button green href="/turmControls" rotate>
            Los gehts!
          </Button>
        </div>
      </div>
      <Spacer h={24} />
      <ActionLink>Zurück</ActionLink>
    </div>
  );
}
