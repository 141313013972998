import React from "react";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Pano from "../Threesixty/Threesixty";
import PanoVideo from "../Threesixty/ThreesixtyVideo";
import { ControlsSettings } from "../Turm/Turm";
import "./Kambodscha.scss";
import arrow_back from "./arrow_back";
import arrow from "./arrow";

function getYheight() {
  return (Math.random() - 0.5) * 200;
}

function angleToVector(angle, distance, height = null) {
  return [
    Math.cos(angle) * distance,
    height !== null ? height : getYheight(),
    Math.sin(angle) * distance,
  ];
}

export default function Kambodscha() {
  let nav = useNavigate();
  const [view, setView] = React.useState(0);
  const [overlay, setOverlay] = React.useState(false);

  const views = [
    {
      image: "/360_1.png",
      points: [
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.6 + ((Math.PI * 2) / 13) * 0, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "youtube",
              src: "2Vs2hPNocZ8",
              text: "",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.6 + ((Math.PI * 2) / 13) * 1, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/01_Glyphoglossus molossus Image_ACCB Wagner.jpg",
              text: "Für den Engmaulfrosch Glyphoglossus molossus ist das ACCB geschützter Lebensraum.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.6 + ((Math.PI * 2) / 13) * 2, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/01_Tokeh Image_ACCB Wagner.jpg",
              text: "Für den Tokeh ist das ACCB Lebensraum.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.6 + ((Math.PI * 2) / 13) * 3, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/01_Nebelwaran Image_ACCB Wagner.jpg",
              text: "Für den Nebelwaran ist das ACCB Lebensraum.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.6 + ((Math.PI * 2) / 13) * 4, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/01_Futterküche Image_ACCB Wagner.jpg",
              text: "In der Futterküche wird Nahrung für ca. 1000 Tiere zubereitet.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.6 + ((Math.PI * 2) / 13) * 5, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/01_Volunteer Building Image_ACCB Wagner.jpg",
              text: "Auf dem Gelände des ACCB leben auch einige der Mitarbeitenden.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.6 + ((Math.PI * 2) / 13) * 6, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/01_Führung Image_ACCB Wagner.jpg",
              text: "Das ACCB bietet täglich Führungen an.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.6 + ((Math.PI * 2) / 13) * 7, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/01_Edukation Image_ACCB Wagner.jpg",
              text: "Das Gebäude für Bildungsprogramme.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.6 + ((Math.PI * 2) / 13) * 8, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/01_Quarantäne Image_ACCB Wagner.jpg",
              text: "Das Quarantäne-Gebäude für Schildkröten.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.6 + ((Math.PI * 2) / 13) * 9, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/01_Batagur pond Image_ACCB Wagner.jpg",
              text: "Anlage für die Südliche Flussschildkröte.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.6 + ((Math.PI * 2) / 13) * 10, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/01_Batagur verletzt Image_ACCB Blumm.jpg",
              text: "Das ACCB kümmert sich auch um verletzt aufgefundene Südliche Flussschildkröten.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.6 + ((Math.PI * 2) / 13) * 11, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/01_Batagur Image_ACCB Wagner.jpg",
              text: "Das ACCB züchtet die kritisch bedrohte Südliche Flussschildkröte.",
            });
          },
        },

        {
          scale: 0.2,
          doIcon: true,
          position: angleToVector(3.6 + ((Math.PI * 2) / 13) * 12, 150, 0),
          isNav: true,
          text: "",
          icon: arrow,
          onClick: () => {
            setView(1);
          },
        },
      ],
    },
    {
      image: "/360_2.png",
      points: [
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(5.7 + ((Math.PI * 2) / 7) * 0, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/02_Wollhalsstorch Image_ACCB Wagner.JPG",
              text: "Die Bestände des Wollhalsstorchs gehen zurück, daher wildert das ACCB seine Nachzuchten aus.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(5.7 + ((Math.PI * 2) / 7) * 1, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/02_Schuppentier Image_ACCB Blumm.jpg",
              text: "Immer wieder werden Schuppentiere beschlagnahmt und im ACCB wieder auf die Natur vorbereitet.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(5.7 + ((Math.PI * 2) / 7) * 6, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/02_Peafowl Image_ACCB Wagner.jpg",
              text: "Der Ährenträger-Pfau wird im ACCB gezüchtet und in Schutzgebiete ausgewildert.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(5.7 + ((Math.PI * 2) / 7) * 3, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "youtube",
              src: "_Zj_XGmsNzI",
              text: "",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(5.7 + ((Math.PI * 2) / 7) * 4, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/02_Orienthornvogel_ACCB Wagner.JPG",
              text: "Der Orient-Hornvogel ist nicht bedroht wird aber oft illegal gehalten.",
            });
          },
        },

        {
          scale: 0.2,
          doIcon: true,
          position: angleToVector(5.7 + ((Math.PI * 2) / 7) * 5, 150, 0),
          isNav: true,
          text: "",
          icon: arrow_back,
          onClick: () => {
            setView(0);
          },
        },
        {
          scale: 0.2,
          doIcon: true,
          position: angleToVector(5.7 + ((Math.PI * 2) / 7) * 2, 150, 0),
          isNav: true,
          text: "",
          icon: arrow,
          onClick: () => {
            setView(2);
          },
        },
      ],
    },
    {
      image: "/360_3.png",
      points: [
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(2.6 + ((Math.PI * 2) / 5) * 0, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "youtube",
              src: "K0VflJ0wwsI",
              text: "",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(2.6 + ((Math.PI * 2) / 5) * 3, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/03_Kappengibbon Image_ACCB Wagner.jpg",
              text: "Im ACCB werden Gibbons gehalten die aus Beschlagnahmungen stammen.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(2.6 + ((Math.PI * 2) / 5) * 2, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/03_Langur.JPG",
              text: "Das ACCB hält eine Gruppe Germain Languren. Alle Tiere stammen aus Beschlagnahmungen.",
            });
          },
        },
        {
          scale: 0.2,
          doIcon: true,
          position: angleToVector(3 + ((Math.PI * 2) / 5) * 1, 150, 0),
          isNav: true,
          text: "",
          icon: arrow_back,
          onClick: () => {
            setView(1);
          },
        },
        {
          scale: 0.2,
          doIcon: true,
          position: angleToVector(2.6 + ((Math.PI * 2) / 5) * 4, 150, 0),
          isNav: true,
          text: "",
          icon: arrow,
          onClick: () => {
            setView(3);
          },
        },
      ],
    },
    {
      image: "/360_4.png",
      points: [
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(((Math.PI * 2) / 6) * 2, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/04_Cuora Image_ACCB Wagner.jpg",
              text: "Das ACCB hält die weltweit größte Zuchtgruppe der Amboina-Scharnierschildkröte und wildert Nachzuchten aus.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(((Math.PI * 2) / 6) * 1, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/04_Indotestudo Image 2_ACCB Blumm.jpg",
              text: "Die Gelbkopfschildkröte ist kritisch von der Ausrottung bedroht und wird im ACCB für die Auswilderung gezüchtet.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(((Math.PI * 2) / 6) * 5, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/04_Indotestudo Image_ACCB Wagner.JPG",
              text: "Die Gelbkopfschildkröte ist kritisch von der Ausrottung bedroht und wird im ACCB für die Auswilderung gezüchtet.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(((Math.PI * 2) / 6) * 3, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/04_Indotestudo juveniles Image_ACCB Wagner.JPG",
              text: "Jungtiere der Gelbkopfschildkröte.",
            });
          },
        },
        {
          scale: 0.2,
          doIcon: true,
          position: angleToVector(((Math.PI * 2) / 6) * 4, 150, 0),
          isNav: true,
          text: "",
          icon: arrow_back,
          onClick: () => {
            setView(2);
          },
        },
        {
          scale: 0.2,
          doIcon: true,
          position: angleToVector(-0.2 + ((Math.PI * 2) / 6) * 0, 150, 0),
          isNav: true,
          text: "",
          icon: arrow,
          onClick: () => {
            setView(4);
          },
        },
      ],
    },
    {
      image: "/360_5.png",
      points: [
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(((Math.PI * 2) / 5) * 3, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/05_Argala Breeding Image_ACCB.jpg",
              text: "Der Argala wird im ACCB gehalten und 2022 zum ersten Mal gezüchtet.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(((Math.PI * 2) / 5) * 1, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/05_Argala Image_ACCB Wagner.jpg",
              text: "Der Sunda Marabu wird im ACCB gehalten und gezüchtet.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(((Math.PI * 2) / 5) * 2, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/05_Heosemys Image_ACCB Wagner.jpg",
              text: "Die Zuchtanlage für die Tempelschildkröte und die Riesenerdschildkröte.",
            });
          },
        },
        {
          scale: 0.2,
          doIcon: true,
          position: angleToVector(((Math.PI * 2) / 5) * 0, 150, 0),
          isNav: true,
          text: "",
          icon: arrow_back,
          onClick: () => {
            setView(3);
          },
        },
        {
          scale: 0.2,
          doIcon: true,
          position: angleToVector(-0.2 + ((Math.PI * 2) / 5) * 4, 150, 0),
          isNav: true,
          text: "",
          icon: arrow,
          onClick: () => {
            setView(5);
          },
        },
      ],
    },
    {
      image: "/360_6.png",
      points: [
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(((Math.PI * 2) / 4) * 0, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/06_Riesenibis Image_ACCB.jpg",
              text: "Der Riesenibis ist eine der am stärksten bedrohten Vogelarten weltweit.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(((Math.PI * 2) / 4) * 2, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/06_White shouldered ibis Image_ACCB Wagner.JPG",
              text: "Der Weißschulteribis ist kritisch bedroht und wird im ACCB seit 2023 gezüchtet.",
            });
          },
        },
        {
          scale: 0.2,
          doIcon: true,
          position: angleToVector(-0.3 + ((Math.PI * 2) / 4) * 1, 150, 0),
          isNav: true,
          text: "",
          icon: arrow_back,
          onClick: () => {
            setView(4);
          },
        },
        {
          scale: 0.2,
          doIcon: true,
          position: angleToVector(((Math.PI * 2) / 4) * 3, 150, 0),
          isNav: true,
          text: "",
          icon: arrow,
          onClick: () => {
            setView(6);
          },
        },
      ],
    },

    {
      image: "/hires2.jpg",
      points: [
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.5 + ((Math.PI * 2) / 5) * 0, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/07_Bengaltrappe Beschlagnahme_ACCB Puigcerver.JPG",
              text: "Die Bengaltrappe steht kurz vor der Ausrottung.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.5 + ((Math.PI * 2) / 5) * 1, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/07_Bengaltrappe Image Anlage_ACCB Puigcerver.jpeg",
              text: "Für eine ex-situ Population der Bengaltrappe wurde im ACCB eine eigene Anlage gebaut.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.5 + ((Math.PI * 2) / 5) * 2, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/07_Florican.JPG",
              text: "Ein Männchen der Bengaltrappe im Zuchtzentrum des ACCB.",
            });
          },
        },
        {
          scale: 0.3,
          doIcon: true,
          position: angleToVector(3.5 + ((Math.PI * 2) / 5) * 3, 300),
          text: "",
          onClick: () => {
            setOverlay({
              type: "image",
              src: "/kambodscha/07_Florican juvenile.JPG",
              text: "Ein Küken der Bengaltrappe das im ACCB geschlüpft ist, nachdem das Ei der Natur entnommen wurde.",
            });
          },
        },
        {
          scale: 0.2,
          doIcon: true,
          position: angleToVector(3.5 + ((Math.PI * 2) / 5) * 4, 150, 0),
          isNav: true,
          text: "",
          icon: arrow_back,
          onClick: () => {
            setView(6);
          },
        },
      ],
    },
  ];
  return (
    <>
      {overlay && (
        <div
          className="kambodschaOverlay"
          onClick={() => {
            setOverlay(false);
          }}
        >
          {overlay.type === "image" && (
            <div className="image">
              <img src={overlay.src} alt="kambodscha"></img>
            </div>
          )}
          {overlay.type === "youtube" && (
            <div className="youtube">
              <iframe
                title="Youtube Video"
                class="video"
                style={{ width: "100%", aspectRatio: "16/9" }}
                src={"https://www.youtube.com/embed/" + overlay.src}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                allowfullscreen
              ></iframe>
            </div>
          )}
          <div className="text">{overlay.text}</div>
        </div>
      )}
      {views[view].image && (
        <Pano
          image={views[view].image}
          points={views[view].points}
          level={view}
        >
          {(viewer) => {
            return (
              <>
                <div style={{ height: "100%", position: "relative" }}></div>
                <ControlsSettings viewer={viewer}></ControlsSettings>
                <div className="closeButton" onClick={() => nav("/")}>
                  <FaTimes></FaTimes>
                </div>
              </>
            );
          }}
        </Pano>
      )}
      {views[view].video && (
        <PanoVideo
          video={views[view].video}
          points={views[view].points}
          level={view}
        >
          {(viewer) => {
            return (
              <>
                <div style={{ height: "100%", position: "relative" }}></div>
                <ControlsSettings viewer={viewer}></ControlsSettings>
                <div className="closeButton" onClick={() => nav("/")}>
                  <FaTimes></FaTimes>
                </div>
              </>
            );
          }}
        </PanoVideo>
      )}
    </>
  );
}
