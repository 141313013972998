import React, { useState } from "react";
import Layout from "../../atoms/Layout/Layout";
import TrumpCard from "../../atoms/TrumpCard/TrumpCard";
import { useUser } from "../../zustand/useUser";
import "./Sammlung.scss";
import data from "../../data";
import ActionLink from "../../atoms/ActionLink/ActionLink";
import Spacer from "../../atoms/Spacer/Spacer";
export default function Sammlung() {
  let { collection } = useUser();
  return (
    <Layout className="Sammlung">
      <div className="scrollBox">
        {collection.map((id) => {
          let animal = data.lebewesen.find((l) => l.id === id);
          if (animal) {
            return <CardHolder data={animal}></CardHolder>;
          }
          return null;
        })}
      </div>
      <Spacer h={24}></Spacer>
      <ActionLink href={"/forscher/frage"}>Weiter forschen!</ActionLink>
      <Spacer h={24}></Spacer>
    </Layout>
  );
}

function CardHolder({ data }) {
  let [open, setOpen] = useState(false);
  return (
    <div
      onClick={() => setOpen(!open)}
      className={"holder " + (open ? "open " : " ")}
    >
      <TrumpCard data={data}></TrumpCard>
    </div>
  );
}
