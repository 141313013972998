import React from "react";
import images from "../../images";
import "./Loading.scss";

export default function Loading({ big }) {
  return (
    <div className={"Loading " + (big ? "big " : " ")}>
      <img alt="loading animation" src={images.loading}></img>
    </div>
  );
}
