import React from "react";
import Layout from "../../atoms/Layout/Layout";
import { DifficultySelector } from "../TurmControls/TurmControls";
import "./KambodschaEntry.scss";

export default function KambodschaEntry() {
  return (
    <Layout className="KambodschaEntry">
      <DifficultySelector nextUrl={"/kambodscha"}></DifficultySelector>
    </Layout>
  );
}
