import React from "react";

import Layout from "../../atoms/Layout/Layout";
import "./Baum.scss";

export default function Baum() {
  return (
    <Layout className="Baum">
      <div className="apps">
        <div className="app">
          <div className="title">Der Merantibaum</div>
          <div className="img">
            <img
              alt="donkeys"
              src="./Menara from drone_Alexander Shenkin.JPG"
            ></img>
            <div className="credit">
              Foto: Drohnenansicht Gelber Merantibaum „Menara“/Borneo, Alexander
              Shenkin
            </div>
          </div>
          <div className="title">Herkunft</div>
          <div className="text">
            Das natürliche Verbreitungsgebiet des Gelben Merantibaumes liegt in
            den tropischen Wäldern Malaysias, Bruneis, Indonesiens und
            Thailands.
          </div>
        </div>
      </div>
      <div className="apps">
        <div className="app">
          <div className="img">
            <img alt="donkeys" src="./Menara Stamm_Unding Jami.jpg"></img>
            <div className="credit">
              Foto: Gelber Merantibaum „Menara“/Borneo, Unding Jami
            </div>
          </div>
          <div className="title">
            Ein zu Hause für tausende Pflanzen und Tierarten
          </div>
          <div className="text">
            Baumgiganten wie der Gelbe Merantibaum sind Lebensraum für viele
            Pflanzen und Tiere. Die dichte Krone wird von Pflanzen wie Lianen
            oder Orchideen sowie tausenden Insekten, Vögeln und Affen bevölkert.
            In jeder Baum-Etage wohnen und leben unterschiedliche Arten zusammen
            mit dem Baum oder nutzen Teile von ihm als Nahrungsquelle.
          </div>
        </div>
      </div>
      <div className="apps">
        <div className="app">
          <div className="img">
            <img
              alt="donkeys"
              src="./Indonesien_OroVerde E. Mannigel.JPG"
            ></img>
            <div className="credit">
              Foto: Waldabholzung/Indonesien, E. Mannigel/OroVerde
            </div>
          </div>
          <div className="title">Baumriese in Gefahr</div>
          <div className="text">
            Der Bestand des Gelben Merantibaumes ist durch illegale Abholzung
            und Zerstörung seines Lebensraumes für den Ausbau
            landwirtschaftlicher Flächen stark zurückgegangen und die Baumart
            äußerst bedroht. Das Holz ist zudem wegen seiner Robustheit ein
            beliebtes Baumaterial. Es wird für den Bau von Fenstern, Türen und
            Möbeln, aber auch Booten verwendet.
          </div>
        </div>
      </div>
    </Layout>
  );
}
