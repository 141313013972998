import React from "react";

import "./TrumpCard.scss";

export default function TrumpCard({
  onClick = () => {},
  data = { image: {} },
}) {
  return (
    <div className="TrumpCard card" onClick={onClick}>
      <div className="cardInner">
        <div className="cardHeader">
          <div className="name">
            <div className="mainName">{data.name}</div>
            <div className="subName">{data.fachbegriff}</div>
          </div>
        </div>
        <div className="imgWrap">
          <img className="cardImage" alt="Panda" src={data.image.url}></img>
          <div className="cloud">
            <svg
              width="100%"
              viewBox="0 0 1280 86"
              preserveAspectRatio="xMidYMid slice"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  d="M1280 66.1c-3.8 0-7.6.3-11.4.8-18.3-32.6-59.6-44.2-92.2-25.9-3.5 2-6.9 4.3-10 6.9-22.7-41.7-74.9-57.2-116.6-34.5-14.2 7.7-25.9 19.3-33.8 33.3-.2.3-.3.6-.5.8-12.2-1.4-23.7 5.9-27.7 17.5-11.9-6.1-25.9-6.3-37.9-.6-21.7-30.4-64-37.5-94.4-15.7-12.1 8.6-21 21-25.4 35.2-10.8-9.3-24.3-15-38.5-16.2-8.1-24.6-34.6-38-59.2-29.9-14.3 4.7-25.5 16-30 30.3-4.3-1.9-8.9-3.2-13.6-3.8-13.6-45.5-61.5-71.4-107-57.8a86.38 86.38 0 0 0-43.2 29.4c-8.7-3.6-18.7-1.8-25.4 4.8-23.1-24.8-61.9-26.2-86.7-3.1-7.1 6.6-12.5 14.8-15.9 24-26.7-10.1-56.9-.4-72.8 23.3-2.6-2.7-5.6-5.1-8.9-6.9-.4-.2-.8-.4-1.2-.7-.6-25.9-22-46.4-47.9-45.8-11.5.3-22.5 4.7-30.9 12.5-16.5-33.5-57.1-47.3-90.6-30.8-21.9 11-36.3 32.7-37.6 57.1-7-2.3-14.5-2.8-21.8-1.6C84.8 47 55.7 40.7 34 54.8c-5.6 3.6-10.3 8.4-13.9 14-6.6-1.7-13.3-2.6-20.1-2.6-.1 0 0 19.8 0 19.8h1280V66.1z"
                  fillOpacity=".5"
                ></path>
                <path d="M15.6 86H1280V48.5c-3.6 1.1-7.1 2.5-10.4 4.4-6.3 3.6-11.8 8.5-16 14.5-8.1-1.5-16.4-.9-24.2 1.7-3.2-39-37.3-68.1-76.4-64.9-24.8 2-46.8 16.9-57.9 39.3-19.9-18.5-51-17.3-69.4 2.6-8.2 8.8-12.8 20.3-13.1 32.3-.4.2-.9.4-1.3.7-3.5 1.9-6.6 4.4-9.4 7.2-16.6-24.9-48.2-35-76.2-24.4-12.2-33.4-49.1-50.6-82.5-38.4-9.5 3.5-18.1 9.1-25 16.5-7.1-6.9-17.5-8.8-26.6-5-30.4-39.3-87-46.3-126.2-15.8-14.8 11.5-25.6 27.4-31 45.4-4.9.6-9.7 1.9-14.2 3.9-8.2-25.9-35.8-40.2-61.7-32-15 4.8-26.9 16.5-31.8 31.5-14.9 1.3-29 7.2-40.3 17-11.5-37.4-51.2-58.4-88.7-46.8-14.8 4.6-27.7 13.9-36.7 26.5-12.6-6-27.3-5.7-39.7.6-4.1-12.2-16.2-19.8-29-18.4-.2-.3-.3-.6-.5-.9-24.4-43.3-79.4-58.6-122.7-34.2-13.3 7.5-24.4 18.2-32.4 31.2C99.8 18.5 50 28.5 25.4 65.4c-4.3 6.4-7.5 13.3-9.8 20.6z"></path>
              </g>
            </svg>
          </div>
        </div>
        <div className="cardInfos">
          <CardInfo
            text={data.type === "Pflanze" ? "Vorkommen" : "Gewicht in Kg"}
            sub={data.type === "Pflanze" ? "" : "(Höchstgewicht)"}
            value={data.gewicht}
            valueSub={""}
          ></CardInfo>
          <CardInfo
            text={data.type === "Pflanze" ? "Blüte" : "Alter in Jahren"}
            sub={data.type === "Pflanze" ? "" : "(in menschlicher Obhut)"}
            value={data.alter}
            valueSub={""}
          ></CardInfo>
          <CardInfo
            text={
              data.type === "Pflanze"
                ? "Frucht/Samen"
                : "Brut/Tragezeit in Tagen"
            }
            sub={data.type === "Pflanze" ? "" : "(durchschnittlich)"}
            value={data.brutzeit}
          ></CardInfo>
          <CardInfo
            text={data.type === "Pflanze" ? "Verwendung" : "Eier/Jungtiere"}
            sub={data.type === "Pflanze" ? "" : "(durchschnittlich)"}
            value={data.nachkommen}
            valueSub={""}
          ></CardInfo>
          {data.type !== "Pflanze" && (
            <>
              <CardInfo
                text={"Bedrohlichkeit"}
                sub={""}
                value={data.bedrohung}
                valueSub={""}
              ></CardInfo>
              <CardInfo
                text={"Häufigkeit in Zoos"}
                sub={""}
                value={data.auftreten}
                valueSub={""}
              ></CardInfo>
            </>
          )}
        </div>
        <div className="otherFacts">
          <div className="title">Wusstest du schon?</div>
          {data.beschreibung}
        </div>
      </div>
    </div>
  );
}

function CardInfo({ text, sub, value, valueSub }) {
  return (
    <div className="cardInfo">
      <div className="text">
        <div className="main">{text}</div>
        <div className="sub">{sub}</div>
      </div>
      <div className="value">
        <div className="main">{value}</div>
        <div className="sub">{valueSub}</div>
      </div>
    </div>
  );
}
